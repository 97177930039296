import React from "react";
import Section from "../layout/section";
import LazyLoad from "react-lazyload";
import prettifyNumber from "../../core/prettify-number";

interface ICardProps {
  make: string;
  model: string;
  year: number;
  price: number;
  description?: React.ReactNode;
  mileage: number;
  transmission: "manual" | "auto";
  className?: string;
  onClick?: Function;
  image: string;
}

const Card: React.FC<ICardProps> = ({
  make,
  model,
  year,
  price,
  description,
  mileage,
  transmission,
  className,
  onClick,
  image
}) => {
  return (
    <div
      className={`${className} bg-primary-100 text-tertiary-700 rounded overflow-hidden border-b-3 shadow-md`}
    >
      <header
        onClick={m => {
          onClick !== undefined ? onClick() : null;
        }}
        className="self-center align-middle transition duration-500 ease-in-out hover:bg-primary-100 hover:opacity-75 transform cursor-pointer"
      >
        {image ? (
          <LazyLoad height={800} offset={400} once={true}>
            <img alt={`${make} ${model} ${year}`} src={image} />
          </LazyLoad>
        ) : null}
      </header>
      <Section className="px-6 py-2 z-50">
        <h1 className="text-md font-semibold text-tertiary-500">
          {make} {model} ⋅ {year}{" "}
        </h1>
        {price ? (
          <div className="flex flex-row text-2xl">
            <h2 className="font-bold table rounded-md">
              {prettifyNumber(price)}
            </h2>
            <small className="font-normal self-center pl-2">EUR</small>
          </div>
        ) : null}
        <p className="mt-4 text-gray-700 text-base">{description}</p>
      </Section>
      <footer className="px-6 py-3 border-t border-primary-400 border-solid flex font-bold">
        <div className="flex-1 flex items-center">
          <svg
            className="w-6 h-6 fill-current mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M8.127 13.6c-.689 1.197-.225 2.18.732 2.732.956.553 2.041.465 2.732-.732.689-1.195 5.047-11.865 4.668-12.084-.379-.219-7.442 8.888-8.132 10.084zM10 6c.438 0 .864.037 1.281.109.438-.549.928-1.154 1.405-1.728A9.664 9.664 0 0010 4C4.393 4 0 8.729 0 14.766c0 .371.016.742.049 1.103.049.551.54.955 1.084.908.551-.051.957-.535.908-1.086A10.462 10.462 0 012 14.766C2 9.85 5.514 6 10 6zm7.219 1.25c-.279.75-.574 1.514-.834 2.174C17.4 10.894 18 12.738 18 14.766c0 .316-.015.635-.043.943a1.001 1.001 0 001.992.182c.033-.37.051-.748.051-1.125 0-2.954-1.053-5.59-2.781-7.516z" />
          </svg>
          {prettifyNumber(mileage, 0)}
          <span className="font-normal ml-1">km</span>
        </div>
        <div className="flex-1 flex items-center capitalize">
          <svg
            className="w-6 h-6 fill-current mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M18 14.824V12.5A3.5 3.5 0 0014.5 9h-2A1.5 1.5 0 0111 7.5V5.176A2.395 2.395 0 0012.4 3a2.4 2.4 0 10-4.8 0c0 .967.576 1.796 1.4 2.176V7.5A1.5 1.5 0 017.5 9h-2A3.5 3.5 0 002 12.5v2.324A2.396 2.396 0 003 19.4a2.397 2.397 0 001-4.576V12.5A1.5 1.5 0 015.5 11h2c.539 0 1.044-.132 1.5-.35v4.174a2.396 2.396 0 001 4.576 2.397 2.397 0 001-4.576V10.65c.456.218.961.35 1.5.35h2a1.5 1.5 0 011.5 1.5v2.324A2.395 2.395 0 0014.6 17a2.4 2.4 0 104.8 0c0-.967-.575-1.796-1.4-2.176zM10 1.615a1.384 1.384 0 110 2.768 1.384 1.384 0 010-2.768zm-7 16.77a1.385 1.385 0 110-2.77 1.385 1.385 0 010 2.77zm7 0a1.385 1.385 0 110-2.77 1.385 1.385 0 010 2.77zm7 0a1.385 1.385 0 110-2.77 1.385 1.385 0 010 2.77z" />
          </svg>
          {transmission}
        </div>
      </footer>
    </div>
  );
};

export default Card;
